.home {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  
    .background-video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; /* Assure que la vidéo couvre tout l'écran */
      z-index: -1;
    }

    .overlay-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
  
     
  .profil{
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    @media (max-width:669px) {
        width: 110px;
        height: 110px;
        
    }
}
      .title {
        color: white;
        font-size: 48px;
        margin-bottom: 0px;
        @media (max-width:669px) {
            font-size: 24px;
            margin-bottom: 10px;
            
        }
      }
  
      .custom-btn {
        background-color: #ff7f50;
        color: white;
        padding: 10px 20px;
        font-size: 16px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: #ff4500;
        }
        @media (max-width:669px) {
           padding: 5px 10px;

            
        }
      }
    }
  }
  
.slider {
    .carousel-item {
      position: relative;
      height: 90vh; /* Full viewport height for each slide */
    }
  
    .background-video {
      width: 100%;
      height: 100%;
      object-fit: cover; /* Ensure video covers the entire slide */
    }
  }
  
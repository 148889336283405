.still-card {
  width: 100%;
  height: 390px !important;
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

   
  }

  .image-wrapper {
      background: #000;
    overflow: hidden;
    border-radius: 15px 15px 0 0;
  }

  .still-card-img {
    transition: transform 0.3s ease;
    object-fit: contain;
    height: 100%;
    width: 100%;

    &:hover {
      transform: scale(1.05); // Slight zoom on hover
    }
  }

  @media (max-width:998px) {
    height: 270px !important;
    
  }
}

.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out;
    color: rgb(250, 247, 247);
    z-index: 3000;
    &.visible {
      opacity: 1;
      visibility: visible;
    }
  }
.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
   img{
    width: 100vw;
    height: 90vh;
    object-fit: cover;
    @media (max-width: 669px) {
      width: 100vw;
      height: 30vh;
      object-fit: cover;
   }
   }

   h1,p {
    color: antiquewhite;
    text-align: justify;
   }
  
    .profil {
      width: 500px !important;
      height: 500px !important;
      overflow: hidden;
  
      @media (max-width: 669px) {
        width: 200px !important;
        height: 200px !important;
      }
  
      img {
        width: 100%;
        height: 100%;
        object-fit: contain; /* Pour garder les proportions de l'image */
      }
    }
  }
  